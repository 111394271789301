export default {
  company: {
    company: 'Company',
    companies: 'Companies',
    companyData: 'Company data',
    overviewAndAdministrate: 'Overview and administrate registered companies.',
    street: 'Street',
    postalcode: 'Postcode',
    postbox: 'P.O. Box',
    postboxPostalcode: 'P.O. Box ZIP',
    city: 'City',
    country: 'Country',
    federalState: 'Federal state',
    phone: 'Phone',
    fax: 'Fax',
    email: 'E-mail',
    emailConfirmedAt: 'Email confirmed at',
    emailVerified: 'E-mail verified',
    website: 'Website',
    companyHeadquartersMustBeInGermany: 'The company headquarters must be in Germany',
    extractFromRegisterCopy: 'Extract from the commercial register, business registration or similar document proving entrepreneurial activity (PDF), max. 30 MB',
    confirmed: 'Confirmed',
    accredited: 'Accredited',
    notAccredited: 'Not Accredited',
    registrationDate: 'Registration date',
    address: 'Address',
    contactDetails: 'Contact details',
    registerData: 'Register data',
    downloadRegisterExcerpt: 'Download register excerpt',
    hrNumber: 'HR number',
    hrRegisterCourt: 'HR register court',
    sendPasswordReset: 'Send password reset',
    sendConfirmEmail: 'Confirm email-address',
    resendConfirmEmail: 'Resend confirmation email',
    deleteCompany: 'Delete company',
    noUser: 'No login available',
    noUserReasons: 'New users are automatically deleted after 10 days without confirmation of their email address.',
    revokeAccreditation: 'Revoke accreditation',
    revokeAccreditationModal: 'Are you sure you want to revoke accreditation?',
    acceptInviteModal: 'This email address is already assigned to another company.',
    acceptInviteModalConfirmButtonLabel: 'Invite user',
    acceptInvite: 'Accept Invite',
    declineInvite: 'Decline Invite',
    acceptInviteConfirmamtion: 'Are you sure you want to accept the invitation?',
    declineInviteConfirmamtion: 'Are you sure you want to decline the invitation?',
    acceptInviteTitle: 'Invite accepted',
    acceptInviteText: 'You have just successfully transferred your user account to another company. Please log in again.',
  },
}
