export default {
  common: {
    title: 'German Pavilion  - Online-Anmeldung',
    description: 'German Pavilion  - Online-Anmeldung',
    federalForeignTradeFairProgram: 'Das Auslandsmesseprogramm des Bundes',
    inCooperationWith: 'in Kooperation mit',
    organizer: 'Veranstalter',
    continue: 'weiter',
    save: 'Speichern',
    apply: 'Anwenden',
    support: 'Support',
    noDataAvailable: 'Keine Daten vorhanden',
    linesPerPage: 'Zeilen pro Seite',
    all: 'Alle',
    close: 'schließen',
    view: 'Ansicht',
    min: 'min',
    max: 'max',
    per: 'pro',
    sqm: '㎡',
    none: 'keine',
    total: 'Summe',
    meter: 'Meter',
    kg: 'kg',
    actions: 'Aktionen',
    edit: 'bearbeiten',
    delete: 'löschen',
    confirm: 'Bestätigen',
    confirmModal: 'Bitte bestätigen',
    cancel: 'Abbrechen',
    deleteConfirmation: 'Sind Sie sicher, dass Sie {item} löschen möchten?',
    generalConfirmation: 'Sind Sie sicher, dass Sie {action} möchten?',
    changeProject: 'Projekt wechseln',
    copiedSuccessfully: 'Erfolgreich kopiert',
    link: 'Link',
    fullLinkTag: 'Vollständiges Link-Tag',
    id: 'ID',
    yes: 'Ja',
    no: 'Nein',
    documents: {
      imprint: 'Impressum',
      privacyPolicy: 'Datenschutz',
    },
    genders: {
      male: 'Herr',
      female: 'Frau',
      divers: 'Genderneutral',
    },
    salutation: {
      male: 'Herr',
      female: 'Frau',
      divers: '',
      null: ''
    },
    default: {
      country: 'Deutschland',
    },
    pageNotFound: 'Seite nicht gefunden',
    pageNotFoundText: 'Die Seite konnte nicht gefunden werden.',
    serverErrors: 'Serverfehler',
    serverErrorsText: '<p>Bitte warten Sie einige Minuten bevor Sie es erneut versuchen.</p>' +
    '<p>Sollte diese Meldung weiterhin erscheinen:</p><p> Loggen Sie sich aus und wieder ein. Auch kann es helfen, den Browsercache, der Seite auf der dieser Fehler aufgetreten ist, zu leeren. Bei einem Windows-System drücken Sie hierzu auf der entsprechenden Seite die Tastenkombination Strg + F5, bei einem Mac ⌘ + Shift + R.</p>'+
    '<p>Bei weiteren Fragen hilft Ihnen das Support-Team vom German Pavilion unter <a href="mailto:support@german-pavilion.com" title="Support German Pavilion">support@german-pavilion.com</a> gerne weiter.</p>',
    errorApiMessage: 'Es ist ein Fehler beim Speichern Ihrer Daten aufgetreten',
    errorFetchApiMessage: 'Beim Abrufen von Daten ist ein Fehler aufgetreten',
    successApiMessage: 'Daten erfolgreich gespeichert',
    incorrectDisplayTitle: 'Displaygröße zu gering',
    incorrectDisplayButton: 'Link per E-Mail senden',
    incorrectDisplayBody:
      'Für die korrekte Darstellung und reibungslose Nutzung dieser Online-Anmeldeplattform benötigen Sie ein\n' +
      '                  Endgerät mit einer minimalen Bildschirmbreite von 1.024 Pixel (z.B. Tablet oder Desktop-PC). Die\n' +
      '                  Anwendung ist für Smartphones nicht geeignet.',
    atbLink: 'https://www.german-pavilion.com/de/portal/atb',
    back: 'Zurück',
    range: {
      select: 'Bitte wählen',
      true: 'Trifft zu',
      false: 'Trifft nicht zu',
    }
  }
}
