export default {
  users: {
    user: 'Nutzer',
    users: 'Benutzer',
    manageUserAccess: 'Nutzerzugänge verwalten',
    manageUserTypesAndAccess: 'Benutzerarten und Benutzerzugänge verwalten.',
    type: 'Nutzertyp',
    name: 'Name',
    organizers: 'Organisationen',
    belongsToAssociation: 'Dieser Nutzer gehört zu Verband:',
    belongsToCompany: 'Dieser Nutzer gehört zur Durchfürhungsgesellschaft',
    canManageOtherUsersAndPMs:
      'Darf weitere Nutzer und Projektleiter für Messen verwalten',
    loginData: 'Zugangsdaten',
    confirmedEmailAddress: 'E-Mail-Adresse bestätigt',
    emailAddressNotConfirmed: 'E-Mail-Adresse ist nicht bestätigt',
    roles: {
      exhibitor: 'Aussteller',
      association: 'Verband',
      organizer: 'Organisator-Administrator',
      administrator: 'Admin',
      supervisor: 'Bundesministerien / AUMA',
      project_manager: 'Projektleiter',
    },
    actions: {
      create: 'Nutzer erstellen',
      createInvite: 'Nutzer erstellen/einladen',
      activate: 'Nutzer aktivieren',
      deactivate: 'Nutzer deaktivieren',
      delete: 'Nutzer löschen',
    },
    userManagement: {
      assignedTo: 'Zugewiesen',
      title: 'Nutzerverwaltung',
      description: 'Zugangsdaten und Berechtigungen verwalten, Projektleiter',
      active: 'Aktiv',
      assignedProjects: 'Zugeordnete Projekte',
      chooseProject: 'Projekt wählen',
      admin: 'Admin',
      canManage: 'Darf Nutzer und Projektleiter verwalten',
      emailConfirmedAt: 'E-Mail-Addresse bestätigt am:', 
      actions: {
        createUser: 'Nutzer erstellen',
        activateUser: 'Nutzer aktivieren',
        deactivateUser: 'Nutzer deaktivieren',
        deleteUser: 'Nutzer löschen',
        resendConfirmationEmail: 'Bestätigungs-E-Mail erneut senden',
      },
    },
    wasInvited: 'wurde eingeladen',
  },
}
