<template>
  <v-container class="pa-9" fluid>

    <v-card class="elevation-1 rounded-0 mb-5 primary--text">
      <v-card-title>
        <h3>{{ $t('settings.dataAndSettings') }}</h3>
      </v-card-title>
      <v-card-text>
        <p class="text--primary">{{ $t('settings.manageSettings') }}</p>
      </v-card-text>
    </v-card>

    <v-expansion-panels 
      v-model="activePanels"
      class="mb-10 elevation-0 rounded-0"
      multiple
      flat
    >
      <v-expansion-panel
        v-for="panel in panels"
        :key="panel.key"
        class="elevation-1 rounded-0"
      >
        <v-expansion-panel-header>
          <h3>{{ panel.title }}</h3>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <component :is="panel.component" />
        </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import UserCredentialsSettings from '@/components/exhibitor/Settings/UserCredentials'
import AccreditationSettings from '@/components/exhibitor/Settings/Accreditation'
import InterestProfileSettings from '@/components/exhibitor/Settings/InterestProfile'
import ContactForm from "@/components/exhibitor/Company/ContactForm.vue"

export default {
  name: 'ExhibitorSettings',

  components: {
    ContactForm,
    UserCredentialsSettings,
    AccreditationSettings,
    InterestProfileSettings,
  },

  data() {
    return {
      activePanels: [],
      panels: [
        {
          key: 'user',
          title: this.$t('settings.sections.userCredentials.title'),
          component: 'UserCredentialsSettings',
        },
        {
          key: 'contact',
          title: this.$t('forms.fields.contactTradeFairParticipation'),
          component: 'ContactForm',
        },
        {
          key: 'accreditation',
          title: this.$t('settings.sections.companyData.title') + ' / ' + this.$t('settings.sections.accreditation.title'),
          component: 'AccreditationSettings',
        },
        {
          key: 'interest',
          title: this.$t('settings.sections.profileOfInterest.title'),
          component: 'InterestProfileSettings',
        },
      ],
    }
  },
  mounted() {
    // open panel by key from route
    const showKey = this.$route.query['show']
    if (showKey) {
      this.panels.forEach((panel, index) => {
        if (panel.key === showKey) {
          this.activePanels.push(index)
        }
      });
    }
  },
}
</script>
